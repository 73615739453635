import React from 'react'
import './Footer.css'

const Footer = () => {

  return (
    <footer>&copy; Jack Sypek - Denver, CO</footer>
  )
}
export default Footer
